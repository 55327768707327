import { createSlice } from '@reduxjs/toolkit';

import { ProGalleryConverter } from 'common/ContentEditor/plugins/pro-gallery/ProGalleryConverter';

import { injectWarmupData } from '../actions';
import { mediaItemsAdapter } from './adapter';
import { fetch } from './thunks';

import { IMediaExtras } from './types';

export const initialState = mediaItemsAdapter.getInitialState<IMediaExtras>({
  loading: false,
  error: false,
});

export const slice = createSlice({
  name: 'media',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(
      injectWarmupData,
      (state, action) => action.payload?.media || state,
    );

    builder
      .addCase(fetch.pending, function (state) {
        state.loading = true;
        state.error = false;
      })
      .addCase(fetch.rejected, function (state) {
        state.loading = false;
        state.error = true;
      })
      .addCase(fetch.fulfilled, function (state, action) {
        const { items } = action.payload;

        state.loading = false;
        state.error = false;
        mediaItemsAdapter.setAll(
          state,
          items
            .map((item) => {
              const items = ProGalleryConverter.convertContentEntity(
                item.entity as any,
              );

              return items.map((media) => ({
                ...media,
                metaData: {
                  ...media.metaData,
                  description: item.createdBy,
                },
              }));
            })
            .flat(),
        );
      });
  },
});
