import {
  EventFieldset,
  SortOrder,
} from '@wix/ambassador-events-v1-event/types';
import { queryEventsV2 } from '@wix/ambassador-events-v1-event/http';

import { listEvents as listEventsByGroupId } from '@wix/ambassador-social-groups-v1-group-event/http';

import { createAsyncThunk } from '../utils';

export const fetch = createAsyncThunk(
  'events:fetch',
  async function (groupId: string, thunkAPI) {
    const { data } = await thunkAPI.extra.httpClient.request(
      listEventsByGroupId({ groupId }),
    );
    const eventIds = data?.eventIds ?? [];

    if (eventIds.length === 0) {
      return [];
    }

    const queryEventsResponse = await thunkAPI.extra.httpClient.request(
      queryEventsV2({
        facet: ['status'],
        fieldset: [
          EventFieldset.DETAILS,
          EventFieldset.REGISTRATION,
          EventFieldset.URLS,
        ],
        query: {
          filter: {
            eventId: {
              $hasSome: eventIds,
            },
            status: {
              $ne: 'CANCELED',
            },
          },
          sort: [{ fieldName: 'start', order: SortOrder.ASC }],
          paging: {
            limit: 100,
          },
        },
      }),
    );

    return queryEventsResponse.data?.events ?? [];
  },
);
