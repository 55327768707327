import { ImageTransformOptions, sdk } from '@wix/image-kit';
import { parseImageName, parseWidthHeight } from 'common/ContentEditor/utils';

type TupleToObject<
  T extends readonly any[],
  M extends Record<Exclude<keyof T, keyof any[]>, PropertyKey>,
> = { [K in Exclude<keyof T, keyof any[]> as M[K]]: T[K] };

type ScaleImageArgs = Parameters<typeof sdk.getScaleToFitImageURL>;
type ScaleImageRecord = TupleToObject<
  ScaleImageArgs,
  [
    'relativeUrl',
    'sourceWidth',
    'sourceHeight',
    'targetWidth',
    'targetHeight',
    'options',
  ]
>;

// making "options" optional again
export type ScaleImageOptions = Omit<
  ScaleImageRecord,
  'options' | 'sourceWidth' | 'sourceHeight'
> & {
  options?: ImageTransformOptions;
  sourceWidth?: number;
  sourceHeight?: number;
};

export type IImageDescriptor =
  | {
      relativeUrl: string;
      sourceWidth: number;
      sourceHeight: number;
      targetWidth: number;
      targetHeight?: number;
      options?: ImageTransformOptions;
    }
  | {
      url: string;
      sourceWidth?: number;
      sourceHeight?: number;
      targetWidth: number;
      targetHeight?: number;
      options?: ImageTransformOptions;
    };

export function getFullImageDescriptor(
  descriptor: IImageDescriptor,
): ScaleImageOptions | undefined {
  try {
    // save source ratio
    const descriptorWidth = descriptor.sourceWidth;
    const descriptorHeight = descriptor.sourceWidth;

    const autoTargetHeight = descriptorHeight
      ? descriptorHeight
      : (descriptor.targetWidth / (descriptorWidth ?? 1)) *
        (descriptor.sourceHeight ?? 1);

    if ('relativeUrl' in descriptor) {
      return {
        ...descriptor,
        targetHeight: autoTargetHeight,
      };
    }

    return {
      ...descriptor,
      targetHeight: autoTargetHeight,
      relativeUrl: parseImageName(descriptor.url),
      sourceWidth:
        descriptor.sourceWidth || parseWidthHeight(descriptor.url).width,
      sourceHeight:
        descriptor.sourceHeight || parseWidthHeight(descriptor.url).height,
    };
  } catch (e) {
    console.log('Error in getFullImageDescriptor', e);
  }
}
