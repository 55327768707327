import type { CommentsProps } from '@wix/comments-ooi-client';

import type { IRootState } from 'store/types';

import type { IApplicationVM } from './application.vm';
import type { ICentralFeedVM } from './central-feed.vm';
import type { ICommentsVM } from './comments.vm';
import type { IEventsVM } from './events.vm';
import type { IFeedVM } from './feed.vm';
import type { IFilesVM } from './files.vm';
import type { IGroupVM } from './group.vm';
import type { IGroupsVM } from './groups.vm';
import type { IMediaVM } from './media.vm';
import type { IMembersVM } from './members.vm';
import type { IRouterVM } from './router.vm';
import type { ITopicsVM } from './topics.vm';
import type { IGroupRequestsVM } from './group-requests.vm';

export type IVM = IApplicationVM &
  ICentralFeedVM &
  ICommentsVM &
  IEventsVM &
  IFeedVM &
  IFilesVM &
  IGroupVM &
  IGroupsVM &
  IMediaVM &
  IMembersVM &
  IRouterVM &
  ITopicsVM &
  IGroupRequestsVM;

export type IVMMethods = Omit<IVM, '_'>;

export type IVMProps = IVMMethods & CommentsProps & { store: IRootState };

export enum ESettingsEvents {
  RequestState = 'requestState',
}

export type SettingsEventHandler = {
  [ESettingsEvents.RequestState]: string;
};
