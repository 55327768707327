/**
 * @see ./README.md
 */

export enum ProGalleryTypes {
  IMAGE = 'image',
  VIDEO = 'video',
  TEXT = 'text',
}

export interface IProGalleryMetadata {
  type: ProGalleryTypes;
  height: number;
  width: number;
  title: string;
  description: any;
  link?: {
    url: string;
    target?: string;
  };
  backgroundColor?: string;
}

export interface IProGalleryItem {
  itemId: string;
  metaData: IProGalleryMetadata;
}
