import { createSlice } from '@reduxjs/toolkit';

import { injectWarmupData } from '../actions';
import { filesItemsAdapter } from './adapter';
import { fetch } from './thunks';

import { IFilesExtras } from './types';

export const initialState = filesItemsAdapter.getInitialState<IFilesExtras>({
  loading: false,
  error: false,
});

export const slice = createSlice({
  name: 'files',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(
      injectWarmupData,
      (state, action) => action.payload?.files || state,
    );

    builder
      .addCase(fetch.pending, function (state) {
        state.loading = true;
        state.error = false;
      })
      .addCase(fetch.rejected, function (state) {
        state.loading = false;
        state.error = true;
      })
      .addCase(fetch.fulfilled, function (state, action) {
        state.loading = false;
        state.error = false;

        filesItemsAdapter.setAll(state, action.payload);
      });
  },
});
