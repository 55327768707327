export enum EFilterKeys {
  ID = 'feedItemId',
  TOPICS = 'entity.topics',
  ATTACHMENTS_TYPE = 'entity.attachments.mediaType',
  CURSOR = 'page',
}

export const DEFAULT_ROLES = {
  isAdmin: false,
  isMember: false,
  role: 'user',
};

export const COSTLY_TIME_BUDGET = 30000;
