import { createSlice } from '@reduxjs/toolkit';
import { PagingMetadata } from '@wix/ambassador-social-groups-v2-group-request/types';

import { injectWarmupData } from '../actions';
import { groupRequestsAdapter } from './adapter';

import * as thunks from './thunks';

interface IStatus {
  loading?: boolean;
  error?: boolean;
}

export const initialState = groupRequestsAdapter.getInitialState({
  meta: {} as PagingMetadata,
  statuses: {
    fetch: {} as IStatus,
    create: {} as IStatus,
    fetchMore: {} as IStatus,
    approve: {} as Record<string, IStatus>,
    decline: {} as Record<string, IStatus>,
    cancel: {} as Record<string, IStatus>,
  },
});

export const groupRequestsSlice = createSlice({
  name: 'groupRequests',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(
      injectWarmupData,
      (state, action) => action.payload?.groupRequests || state,
    );

    builder
      .addCase(thunks.cancel.pending, (state, action) => {
        state.statuses.cancel[action.meta.arg] = {
          loading: true,
          error: false,
        };
      })
      .addCase(thunks.cancel.rejected, (state, action) => {
        state.statuses.cancel[action.meta.arg] = {
          loading: false,
          error: true,
        };
      })
      .addCase(thunks.cancel.fulfilled, (state, action) => {
        state.statuses.cancel[action.meta.arg] = {
          loading: false,
          error: false,
        };

        state.meta.total = state.meta.total ? state.meta.total - 1 : 0;
        groupRequestsAdapter.removeOne(state, action.meta.arg);
      });

    builder
      .addCase(thunks.decline.pending, (state, action) => {
        const { id } = action.meta.arg;

        state.statuses.decline[id] = {
          loading: true,
          error: false,
        };
      })
      .addCase(thunks.decline.rejected, (state, action) => {
        const { id } = action.meta.arg;

        state.statuses.decline[id] = {
          loading: false,
          error: true,
        };
      })
      .addCase(thunks.decline.fulfilled, (state, action) => {
        const { id } = action.meta.arg;

        state.statuses.decline[id] = {
          loading: false,
          error: false,
        };

        state.meta.total = state.meta.total ? state.meta.total - 1 : 0;
        groupRequestsAdapter.removeOne(state, id);
      });

    builder
      .addCase(thunks.approve.pending, (state, action) => {
        state.statuses.approve[action.meta.arg] = {
          loading: true,
          error: false,
        };
      })
      .addCase(thunks.approve.rejected, (state, action) => {
        state.statuses.approve[action.meta.arg] = {
          loading: false,
          error: true,
        };
      })
      .addCase(thunks.approve.fulfilled, (state, action) => {
        state.statuses.approve[action.meta.arg] = {
          loading: false,
          error: false,
        };

        state.meta.total = state.meta.total ? state.meta.total - 1 : 0;
        groupRequestsAdapter.removeOne(state, action.meta.arg);
      });

    builder
      .addCase(thunks.create.pending, (state) => {
        state.statuses.create.loading = true;
        state.statuses.create.error = false;
      })
      .addCase(thunks.create.rejected, (state) => {
        state.statuses.create.loading = false;
        state.statuses.create.error = true;
      })
      .addCase(thunks.create.fulfilled, (state, action) => {
        state.statuses.create.loading = false;
        state.statuses.create.error = false;

        state.meta.total = state.meta.total ? state.meta.total + 1 : 1;
        groupRequestsAdapter.addOne(state, action.payload);
      });

    builder
      .addCase(thunks.fetch.pending, (state, action) => {
        const { offset } = action.meta.arg;

        if (offset) {
          state.statuses.fetchMore.loading = true;
          state.statuses.fetchMore.error = false;
        } else {
          state.statuses.fetch.loading = true;
          state.statuses.fetch.error = false;
        }
      })
      .addCase(thunks.fetch.rejected, (state, action) => {
        const { offset } = action.meta.arg;

        if (offset) {
          state.statuses.fetchMore.loading = false;
          state.statuses.fetchMore.error = true;
        } else {
          state.statuses.fetch.loading = true;
          state.statuses.fetch.error = true;
        }
      })
      .addCase(thunks.fetch.fulfilled, (state, action) => {
        const { offset } = action.meta.arg;

        state.meta = action.payload.metadata;

        if (offset) {
          state.statuses.fetchMore.loading = false;
          state.statuses.fetchMore.error = false;
          groupRequestsAdapter.addMany(state, action.payload.requests);
        } else {
          state.statuses.fetch.loading = false;
          state.statuses.fetch.error = false;
          groupRequestsAdapter.setAll(state, action.payload.requests);
        }
      });
  },
});
