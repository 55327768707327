import { createAction } from '@reduxjs/toolkit';

import type { IRootState } from './types';

/**
 * ugly workaround
 * can't use it via `preloadedState` during initialization because it might arrive later
 */
export const injectWarmupData = createAction<IRootState | undefined | null>(
  'store:warmup-data',
);
