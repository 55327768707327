import type { ControllerParams } from '@wix/yoshi-flow-editor';
import type { Role } from '@wix/ambassador-social-groups-v2-group-role/types';

import * as members from 'store/members';
import { IRootStore } from 'store/index';

import type { IMembersQueryParams } from 'store/members/types';

export function MembersVM(params: ControllerParams, store: IRootStore) {
  return {
    members$: {
      fetchProfile,
      fetch,
      query,
      querySuggested,
      fetchJoinRequests,
      fetchAnswers,
      changeRole,
      add,
      inviteByEmail,
      remove,
      approve,
      reject,
    },
  };

  function fetchAnswers(groupId: string, memberId: string) {
    return store.dispatch(members.thunks.fetchAnswers({ groupId, memberId }));
  }

  function changeRole(groupId: string, memberId: string, role: Role) {
    return store.dispatch(
      members.thunks.changeRole({ groupId, memberId, role }),
    );
  }

  function remove(groupId: string, memberId: string) {
    return store.dispatch(members.thunks.remove({ groupId, memberId }));
  }

  function add(groupId: string, memberIds: string[]) {
    return store.dispatch(members.thunks.addToGroup({ groupId, memberIds }));
  }

  function inviteByEmail(groupId: string, emails: string[]) {
    return store.dispatch(members.thunks.inviteByEmail({ groupId, emails }));
  }

  function fetchJoinRequests(groupId: string) {
    return store.dispatch(members.thunks.fetchJoinRequests(groupId));
  }

  function approve(groupId: string, memberId: string) {
    return store.dispatch(members.thunks.approve({ groupId, memberId }));
  }

  function reject(groupId: string, memberId: string, reason?: string) {
    return store.dispatch(members.thunks.reject({ groupId, memberId, reason }));
  }

  function fetchProfile(memberId: string) {
    return store.dispatch(members.thunks.fetchProfile(memberId));
  }

  function querySuggested(
    groupId: string,
    params: IMembersQueryParams,
    offset: number = 0,
    limit?: number,
  ) {
    return store.dispatch(
      members.thunks.querySuggested({
        groupId,
        limit,
        offset,
        query: {
          nickname: params.nickname,
        },
      }),
    );
  }

  function fetch(groupId: string, limit: number = 10, offset: number = 0) {
    return store.dispatch(
      members.thunks.list({
        groupId,
        limit,
        offset,
      }),
    );
  }

  function query(
    groupId: string,
    params: IMembersQueryParams,
    offset: number = 0,
    limit?: number,
  ) {
    return store.dispatch(
      members.thunks.query({
        groupId,
        limit,
        offset,
        query: {
          role: params.role,
          nickname: params.nickname,
        },
      }),
    );
  }
}

export type IMembersVM = ReturnType<typeof MembersVM>;
