import type { ControllerParams } from '@wix/yoshi-flow-editor';

import type {
  RawParams,
  Transition,
  TransitionOptions,
  UIRouter,
} from '@uirouter/core';

import * as application from 'store/application';
import type { IRootStore } from 'store/index';
import {
  anotherComponentHookCriteria,
  sameComponentHookCriteria,
} from 'router/helpers';

export function RouterVM(
  params: ControllerParams,
  store: IRootStore,
  router: UIRouter,
) {
  const { environment } = params.flowAPI;

  router.transitionService.onBefore(
    { to: sameComponentHookCriteria },
    handleStateUpdate,
  );

  router.transitionService.onError(
    { to: sameComponentHookCriteria },
    handleStateUpdate,
  );

  if (environment.isPreview) {
    router.transitionService.onBefore(
      { to: anotherComponentHookCriteria },
      handleNavigationInEditorPreview,
    );
  }

  return {
    router$: {
      go(state: string, params?: RawParams, options?: TransitionOptions) {
        router.stateService.go(state, params, options);
      },
      reload(state?: string) {
        router.stateService.reload(state);
      },
    },
  };

  async function handleNavigationInEditorPreview(transition: Transition) {
    const { controllerConfig } = params.flowAPI;
    const { wixCodeApi, compId } = controllerConfig;

    const to = transition.to();

    const section = {
      compId,
      noTransition: true,
      sectionId: to.data.sectionId,
      queryParams: { state: to.name, params: transition.params('to') },
    };

    wixCodeApi.location.navigateToSection(section);
  }

  async function handleStateUpdate(transition: Transition) {
    store.dispatch(
      application.actions.setRouterCurrent({
        url: router.urlService.url(),
        state: transition.to().name as string,
        params: transition.params('to'),
      }),
    );
  }
}

export type IRouterVM = ReturnType<typeof RouterVM>;
