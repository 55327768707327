import type {
  HookRegOptions,
  IStateMatch,
  Transition,
  UIRouter,
} from '@uirouter/core';

export type Resolvables = Record<string, any>;

const CORE_RESOLVABLES = ['$stateParams', '$state$', '$transition$'];
const HOOK_OPTIONS: HookRegOptions = { priority: 1000 };

interface ITransitionOptions {
  to: string;
  blacklist?: string[];
  resolvables?: Resolvables;
  disableWaitForResolvables?: boolean;
}

export async function waitForTransition(
  router: UIRouter,
  options: ITransitionOptions,
): Promise<Resolvables> {
  const { transitionService, urlService } = router;
  const { to, resolvables, blacklist = [] } = options;

  return new Promise((resolve, reject) => {
    const restoration = transitionService.onBefore(
      { to },
      handleRestore,
      HOOK_OPTIONS,
    );

    const errorListener = transitionService.onError(
      {},
      handleError,
      HOOK_OPTIONS,
    );

    const successListener = transitionService.onFinish(
      { to },
      handleResolve,
      HOOK_OPTIONS,
    );

    urlService.sync();

    function handleError(transition: Transition) {
      const { redirected } = transition.error();

      // might be redirect to a new group slug
      if (!redirected) {
        cleanup();
        reject();
      }
    }

    async function handleResolve(transition: Transition) {
      if (options.disableWaitForResolvables) {
        cleanup();
        resolve({});
        return;
      }

      const tokens = transition
        .getResolveTokens()
        .filter(
          (token) =>
            typeof token === 'string' &&
            ![CORE_RESOLVABLES, blacklist].flat().includes(token),
        );

      const resolvables = await Promise.all(
        tokens.map(async (token) => {
          const resolvable = await transition.injector().getAsync(token);

          return [token, resolvable];
        }),
      ).catch(() => []);

      cleanup();
      resolve(Object.fromEntries(resolvables));
    }

    function handleRestore(transition: Transition) {
      if (!resolvables) {
        return;
      }

      for (const pathNode of transition.treeChanges().to) {
        for (const resolvable of pathNode.resolvables) {
          /**
           * Using hasOwnProperty, instead of Object.hasOwn, which  has less support and causing:
           * https://sentry-next.wixpress.com/organizations/wix-groups/issues/29677600/?query=is%3Aunresolved&referrer=issue-stream&sort=user&statsPeriod=14d
           *
           * this way of using hasOwnProperty imitates Object.hasOwn behaviour
           * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/hasOwnProperty#using_hasownproperty_as_a_property_name
           */
          if (
            !Object.prototype.hasOwnProperty.call(resolvables, resolvable.token)
          ) {
            continue;
          }

          resolvable.data = resolvables[resolvable.token];
          resolvable.resolved = true;
          resolvable.promise = Promise.resolve(resolvable.data);
        }
      }
    }

    function cleanup() {
      restoration();
      successListener();
      errorListener();
    }
  });
}

export const sameComponentHookCriteria: IStateMatch = (state, transition) => {
  const from = transition?.from();
  const to = transition?.to();

  const fromComponent = from?.data?.sectionId;
  const toComponent = to?.data?.sectionId;

  if (!fromComponent) {
    return true;
  }

  return fromComponent === toComponent;
};

export const anotherComponentHookCriteria: IStateMatch = (
  state,
  transition,
) => {
  const from = transition?.from();
  const to = transition?.to();

  const fromComponent = from?.data?.sectionId;
  const toComponent = to?.data?.sectionId;

  if (!fromComponent) {
    return false;
  }

  return fromComponent !== toComponent;
};
