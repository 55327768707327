import { isEmpty } from 'lodash';
import { ControllerParams } from '@wix/yoshi-flow-editor';

import { IAppData } from '../types';

/**
 * Resolves app data
 *
 * `params.appData` - from viewer.app `initAppForPage`
 * `params.controllerConfig.appParams.appData` - mocked in storybook
 */
export function getAppData(params: ControllerParams): IAppData {
  const appData = !isEmpty(params.appData)
    ? params.appData
    : params.controllerConfig.appParams.appData;

  return appData as IAppData;
}
